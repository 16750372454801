<template>
  <picture>
    <source
      v-for="ext in extensions.slice(0, extensions.length - 1)"
      :key="ext"
      :srcset="`${src}.${ext}${versionParam}`"
      :type="extTypes[ext]"
    />
    <img
      :alt="alt"
      :src="`${src}.${extensions[0]}${versionParam}`"
      :class="imgClass"
      :style="imgStyle"
      :draggable="false"
      :loading="lazy ? 'lazy' : 'eager'"
      onmousedown="return false"
    />
  </picture>
</template>

<script>
const availableExtensions = ['jpg', 'png', 'svg', 'webp'];

export default {
  props: {
    alt: {
      type: String,
      default: '',
    },
    extensions: {
      type: Array,
      required: true,
      validator: (val) => val.every((ext) => availableExtensions.includes(ext)),
    },
    src: {
      type: String,
      required: true,
    },
    imgClass: {
      type: String,
      default: '',
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    version: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    extTypes: {
      jpg: 'image/jpeg',
      png: 'image/png',
      svg: 'image/svg+xml',
      webp: 'image/webp',
    },
  }),
  computed: {
    versionParam() {
      return this.version ? `?v=${this.version}` : '';
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-app-region: no-drag;
}
</style>
