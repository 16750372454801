export default {
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
  filters: {
    asDuration(val) {
      return `${val / 24 / 60 / 60} day(s)`;
    },
  },
};
