<template>
  <footer class="page-footer green">
    <div class="container" style="bottom: 100px; position: absolute; left: 50%; transform: translate(-50%)">
      <div class="row">
        <div class="col l4 s12">
          <h5 class="white-text txt--bold mb-5">Rasylva.id</h5>
          <p class="grey-text text-lighten-4 mb-4">Pengembang aplikasi di bidang Ekologi dan Kehutanan</p>
          <ul class="txt--bold">
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/#home">Home</a>
            </li>
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/#products">Products</a>
            </li>
          </ul>
        </div>
        <div class="col l4 s12 center-align">
          <h5 class="white-text hide-on-small-only hide-on-med-only txt--bold mb-5">Links</h5>
          <ul class="txt--bold mv-6">
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/contact">Contact</a>
            </li>
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/terms">Terms and Condition</a>
            </li>
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/terms">Privacy Policy</a>
            </li>
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/help">Help</a>
            </li>
            <li style="margin: 16px 0">
              <a class="grey-text text-lighten-3" href="/about">About</a>
            </li>
          </ul>
        </div>
        <div class="col l4 s12 right-align">
          <h5 class="white-text hide-on-small-only hide-on-med-only txt--bold mb-5">Contact</h5>
          <ul class="txt--bold">
            <li style="margin: 16px 0">
              <a
                href="https://instagram.com/rasylva.id"
                target="_blank"
                class="grey-text text-lighten-3"
                rel="noreferrer"
                >Instagram: <strong class="white-text">@rasylva.id</strong></a
              >
            </li>
            <li style="margin: 16px 0">
              <a href="https://tiktok.com/@rasylva.id" target="_blank" class="grey-text text-lighten-3" rel="noreferrer"
                >Tiktok: <strong class="white-text">@rasylva.id</strong></a
              >
            </li>
            <li style="margin: 16px 0">
              <a href="https://x.com/rasylva_id" target="_blank" class="grey-text text-lighten-3" rel="noreferrer"
                >𝕏: <strong class="white-text">@rasylva_id</strong></a
              >
            </li>
            <li style="margin: 16px 0">
              <a href="mailto:help@rasylva.id" target="_blank" class="grey-text text-lighten-3" rel="noreferrer"
                >Email: <strong class="white-text">help@rasylva.id</strong></a
              >
            </li>
          </ul>
        </div>
        <div class="col l4 s12 show-on-small-only" style="min-height: 100px"></div>
      </div>
    </div>
    <div class="footer-copyright" style="position: absolute; overflow: inherit; bottom: 0; width: 100%">
      <PictureImg src="/images/landing/feline-green" alt="Feline" :extensions="['svg']" img-class="feline" />
      <div class="container center" style="z-index: 1">
        <span>© 2019-{{ currentYear }} Rasylva.id. All rights reserved.</span>
      </div>
    </div>
  </footer>
</template>

<script>
import PictureImg from '~/components/picture-img';
import datetimeMixin from '~/mixins/datetime';

export default {
  components: {
    PictureImg,
  },
  mixins: [datetimeMixin],
};
</script>

<style lang="scss" scoped>
.page-footer {
  position: relative;
  height: 850px;
  text-align: center;
  @media only screen and (min-width: 993px) {
    text-align: left;
    height: 500px;
  }
  .right-align {
    text-align: center;
    @media only screen and (min-width: 993px) {
      text-align: right;
    }
  }
}

::v-deep .feline {
  height: 150px;
  bottom: 2px;
  right: 50%;
  position: absolute;
  transform: translate(50%);
  @media only screen and (min-width: 993px) {
    height: 150px;
    bottom: 2px;
    right: 16%;
    transform: none;
  }
}
</style>
