<template>
  <div id="app">
    <PageHeader fixed />
    <main>
      <ul class="scroll-nav hide-on-med-and-down">
        <li>
          <a href="#home"><div></div></a>
        </li>
        <li>
          <a href="#why"><div></div></a>
        </li>
        <li>
          <a href="#solution"><div></div></a>
        </li>
        <li>
          <a href="#advantages"><div></div></a>
        </li>
        <li>
          <a href="#products"><div></div></a>
        </li>
        <li>
          <a href="#testimony"><div></div></a>
        </li>
        <li>
          <a href="#contact"><div></div></a>
        </li>
      </ul>
      <section id="home" style="position: relative; overflow: hidden" class="teal">
        <PictureImg
          src="/images/landing/landing-bg"
          alt="Landing Background"
          :extensions="['webp', 'png', 'svg']"
          :lazy="false"
          img-class="landing-bg"
        />
        <PictureImg
          src="/images/landing/eagle"
          alt="Eagle"
          :extensions="['svg', 'webp', 'png']"
          :lazy="false"
          img-class="absolute-center eagle"
        />
        <PictureImg
          src="/images/landing/butterfly-1"
          alt="Butterfly 1"
          :extensions="['svg', 'webp', 'png']"
          :lazy="false"
          img-class="absolute-center butterfly-1"
        />
        <PictureImg
          src="/images/landing/butterfly-2"
          alt="Butterfly 2"
          :extensions="['svg', 'webp', 'png']"
          :lazy="false"
          img-class="absolute-center butterfly-2"
        />
        <PictureImg
          src="/images/landing/rasylva-logo-white"
          alt="Rasylva.id"
          :extensions="['svg', 'webp', 'png']"
          :lazy="false"
          img-class="rasylva-logo-white"
        />
        <PictureImg
          v-for="(firefly, key) in fireflies"
          :key="key"
          src="/images/landing/firefly"
          alt="Firefly"
          :extensions="['svg', 'webp', 'png']"
          :lazy="false"
          img-class="absolute-center firefly"
          :img-style="{ top: firefly.top, left: firefly.left, transform: firefly.transform }"
        />
        <h2 class="landing-quote center" style="color: #dedede">
          Tim pengembangan aplikasi yang bertujuan untuk memudahkan
          <br />
          proses data science di bidang ekologi dan kehutanan
        </h2>
      </section>

      <section id="why" style="position: relative">
        <div class="sky-bg">
          <div class="sky-text flex flex-col gap-4 lg:gap-8 max-w-xl lg:max-w-7xl ph-3">
            <div class="flex flex-col lg:flex-row lg:gap-8 justify-center items-center">
              <PictureImg
                src="/images/landing/technology"
                alt=""
                :extensions="['webp', 'png']"
                :lazy="false"
                img-class="w-[28vh] lg:w-[48vh]"
              />
              <p class="text-balance lg:text-xl lg:text-left">
                Saat ini Indonesia sudah memasuki era di mana teknologi informasi berkembang sangat pesat. Teknologi
                diperlukan manusia untuk memudahkan pekerjaan dan menambah nilai produktivitas serta efisiensi waktu
                maupun tenaga.
              </p>
            </div>
            <div class="flex flex-col lg:flex-row-reverse lg:gap-8 justify-center items-center lg:-mt-16">
              <PictureImg
                src="/images/landing/softwaredev"
                alt=""
                :extensions="['webp', 'png']"
                :lazy="false"
                img-class="w-[28vh] lg:w-[48vh]"
              />
              <p class="text-balance lg:text-xl lg:text-right">
                Pengembangan aplikasi merupakan salah satu contoh pemanfaatan teknologi digital yang diperlukan di
                berbagai bidang sains, begitu pula di bidang ekologi dan kehutanan.
              </p>
            </div>
          </div>
          <PictureImg
            src="/images/landing/hills"
            alt="Hills"
            :lazy="false"
            :extensions="['svg', 'webp', 'png']"
            :img-style="{ bottom: '-2px', position: 'absolute' }"
          />
        </div>
      </section>

      <section id="solution" style="position: relative; background: #83bb60; overflow: hidden">
        <div
          :style="{
            width: '80%',
            margin: 'auto',
            color: 'white',
          }"
        >
          <PictureImg
            src="/images/landing/rasylva-logo-sun"
            alt="Rasylva Logo Sun"
            :extensions="['svg']"
            :style="{ width: '100%' }"
            img-class="w-[70vh] lg:w-[100vh]"
            :img-style="{
              top: '45%',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
            }"
          />
          <p
            class="text-center max-w-xl lg:max-w-7xl text-balance text-lg lg:text-2xl"
            :style="{
              padding: '16px',
              top: '75%',
              left: '50%',
              position: 'absolute',
              transform: 'translate(-50%, -50%)',
              width: '100%',
            }"
          >
            Oleh karena itu, Rasylva.id hadir untuk mempermudah proses pengumpulan dan pengolahan data di bidang ekologi
            dan kehutanan.
          </p>
        </div>
      </section>

      <section id="advantages" style="position: relative">
        <div class="header-space"></div>
        <PictureImg
          src="/images/landing/liana-left"
          alt="Liana Left"
          :extensions="['svg', 'webp', 'png']"
          img-class="liana-left"
        />
        <PictureImg
          src="/images/landing/liana-right"
          alt="Liana Right"
          :extensions="['svg', 'webp', 'png']"
          img-class="liana-right"
        />
        <div class="flex-container center-align advantage">
          <div class="flex-item advantage__item">
            <PictureImg
              src="/images/landing/illus-quality"
              alt="Quality Illustration"
              :extensions="['svg', 'webp', 'png']"
              img-class="lg:mb-2"
              :img-style="{ display: 'block', height: '18vh', maxHeight: '150px', margin: 'auto' }"
            />
            <h4 class="text-base lg:text-2xl m-0 lg:mb-2 txt--bold">Quality Software</h4>
            <span class="text-sm lg:text-xl"
              >Kami membuat aplikasi dengan memikirkan kemudahan penggunaan dan kinerja yang baik.</span
            >
          </div>
          <div class="flex-item advantage__item">
            <PictureImg
              src="/images/landing/illus-affordable"
              alt="Affordable Illustration"
              :extensions="['svg', 'webp', 'png']"
              img-class="lg:mb-2"
              :img-style="{ display: 'block', height: '18vh', maxHeight: '150px', margin: 'auto' }"
            />
            <h4 class="text-base lg:text-2xl m-0 lg:mb-2 txt--bold">Affordable Pricing</h4>
            <span class="text-sm lg:text-xl">
              Kami menyesuaikan harga paket aplikasi agar pelajar, mahasiswa, ataupun profesional dapat menggunakan
              aplikasi kami sesuai kebutuhan.
            </span>
          </div>
          <div class="flex-item advantage__item">
            <PictureImg
              src="/images/landing/illus-friendly"
              alt="Friendly Illustration"
              :extensions="['svg', 'webp', 'png']"
              img-class="lg:mb-2"
              :img-style="{ display: 'block', height: '18vh', maxHeight: '150px', margin: 'auto' }"
            />
            <h4 class="text-base lg:text-2xl m-0 lg:mb-2 txt--bold">Customer Friendly</h4>
            <span class="text-sm lg:text-xl"
              >Kami selalu siap untuk membantu saat anda membutuhkan bantuan dalam penggunaan aplikasi kami.</span
            >
          </div>
        </div>
      </section>

      <section id="products" style="position: relative; height: auto; min-height: 100vh">
        <PictureImg
          src="/images/landing/birds"
          alt="Birds"
          :extensions="['svg', 'webp', 'png']"
          :img-style="{ display: 'block', width: '38vh', margin: 'auto', paddingTop: '12vh' }"
        />
        <h2 class="center-align text-xl lg:text-2xl txt--bold" style="padding-top: 8px">Products</h2>
        <div class="container row grid">
          <div class="s12 m4" style="height: ">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <PictureImg
                  src="/inventum/icons/android-icon-512x512"
                  alt="Inventum Logo"
                  :extensions="['webp', 'png']"
                  :img-style="{ maxWidth: '250px', margin: 'auto', height: '150px', width: 'auto' }"
                />
              </div>
              <div class="card-content" style="height: 280px">
                <span class="card-title">Inventum</span>
                <p>
                  Inventum adalah Alat Inventarisasi Tumbuhan yang dapat membantu kamu untuk menginput data tumbuhan
                  secara offline, dengan output berupa spreadsheet yang dapat kamu unduh (xlsx/csv).
                </p>
              </div>
              <div class="card-action" style="position: absolute; bottom: 0; width: 100%">
                <a class="btn teal white-text" href="/products/inventum">Pelajari Lebih Lanjut</a>
              </div>
            </div>
          </div>
          <div class="s12 m4">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <PictureImg
                  src="/beecounter/icon/png/logo-color"
                  alt="BeeCounter Logo"
                  :extensions="['png']"
                  :img-style="{ maxWidth: '250px', margin: 'auto', height: '150px', width: 'auto' }"
                />
              </div>
              <div class="card-content" style="height: 280px">
                <span class="card-title">BeeCounter</span>
                <p>
                  BeeCounter adalah Alat Penghitung Lebah yang dapat membantu kamu untuk menginput data lebah, dengan
                  output berupa tabel yang dapat kamu copy ke spreadsheet.
                </p>
              </div>
              <div class="card-action" style="position: absolute; bottom: 0; width: 100%">
                <a class="btn teal white-text" href="/beecounter">Buka Aplikasi</a>
              </div>
            </div>
          </div>
          <div class="s12 m4 hide-on-small-only">
            <div class="card" style="height: 100%">
              <div class="card-image">
                <div
                  style="max-width: 250px; height: 250px; margin: auto; height: 150px; width: auto; background: white"
                ></div>
              </div>
              <div class="card-content" style="height: 280px">
                <span class="card-title">Coming soon...</span>
                <p>Tunggu aplikasi kami selanjutnya! :D</p>
              </div>
              <div class="card-action" style="position: absolute; bottom: 0; width: 100%">
                <span class="btn teal white-text" :disabled="true">Coming soon...</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="testimony" style="position: relative">
        <div style="top: 56px; position: relative">
          <h2 class="center-align text-xl lg:text-2xl txt--bold" style="padding-top: 3vh">Testimony</h2>
        </div>
        <div class="testimony-slider">
          <div class="testimony-slider__indicators hide-on-med-and-up">
            <div
              v-for="id in 3"
              :key="id"
              class="testimony-slider__indicators__dot"
              :class="{ active: activeTestimony === id }"
            ></div>
          </div>
          <div ref="testimonySlider" class="testimony-slider__container">
            <div v-for="(testimony, idx) in testimonies" :key="idx" class="testimony-slider__item">
              <div class="testimony-bubble">
                <PictureImg
                  src="/images/landing/testimony-bubble-green"
                  alt="Testimony Bubble"
                  :extensions="['svg', 'webp', 'png']"
                />
                <div class="testimony-bubble__text">
                  <span v-html="testimony.content"></span>
                </div>
              </div>
              <div class="testimony-profile">
                <PictureImg
                  class="testimony-profile__photo"
                  :src="testimony.image"
                  alt="Testimony Photo 1"
                  :extensions="['webp', 'png']"
                  img-class="testimony-profile__image"
                />
                <div class="testimony-profile__text">
                  <b>{{ testimony.name }}</b>
                  <br />
                  <span v-html="testimony.title"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PictureImg
          src="/images/landing/single-tree-green"
          alt="Single Tree"
          :extensions="['webp', 'svg', 'png']"
          :img-style="{ height: '8vh', bottom: '0', left: '10%', position: 'absolute' }"
        />
        <PictureImg
          src="/images/landing/single-tree-green"
          alt="Single Tree"
          :extensions="['webp', 'svg', 'png']"
          :img-style="{ height: '8vh', bottom: '0', left: '50%', position: 'absolute', transform: 'translate(-50%)' }"
        />
        <PictureImg
          src="/images/landing/single-tree-green"
          alt="Single Tree"
          :extensions="['webp', 'svg', 'png']"
          :img-style="{ height: '8vh', bottom: '0', right: '10%', position: 'absolute' }"
        />
      </section>

      <section id="contact" class="green" style="position: relative; height: auto; padding-top: 40px">
        <PageFooter class="landing-footer" />
      </section>
    </main>
  </div>
</template>

<script>
import PageFooter from '~/components/page-footer';
import PageHeader from '~/components/page-header';
import PictureImg from '~/components/picture-img';

function debounce(func, wait = 100) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export default {
  components: {
    PageFooter,
    PageHeader,
    PictureImg,
  },
  data: () => ({
    fireflies: [
      { top: '22%', left: '11%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '21%', left: '17%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '26%', left: '17%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '43%', left: '82%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '46%', left: '71%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '57%', left: '78%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '71%', left: '18%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '66%', left: '25%', transform: 'translate3d(-50%, -50%, 0)' },
      { top: '74%', left: '29%', transform: 'translate3d(-50%, -50%, 0)' },
    ],
    isLoggedIn: false,
    activeTestimony: 1,
    testimonies: [
      {
        image: '/images/landing/testimony-photo-1',
        name: 'Kania Gita Lestari, S.Hut',
        title: 'Asisten Lapang Botani Belantara Mandara,<br />Alumni Fakultas Kehutanan IPB',
        content: `“Dulu aku harus nyiapin dan bawa peralatan mencatat seperti alat
                  tulis, buku, papan jalan, dan peralatan lain yang ribet ke lapang.
                  Tapi sekarang cukup dengan androidku dan aplikasi Inventum saja
                  semua halnya jadi lebih mudah.
                  <br />
                  <br />
                  Inventum itu bagiku seperti ibu peri yang membantu segala
                  pekerjaanku menjadi lebih cepat, seperti penginputan data lapang
                  tanaman yang otomatis rapi di aplikasi, memudahkan perekapan data,
                  bisa dipakai offline, dan juga hasilnya bisa didownload langsung
                  dalam bentuk Excel sehingga aku lebih cepat membuat analisis dan
                  pelaporan data. Kalo ga ada aplikasi Inventum ini, aku ga tau lagi deh
                  seribet apa kalo nganalisis vegetasi yang beribu-ribu individu itu,
                  kepalaku pusing 7 keliling kayanya. So, thank you so much Rasylva.id
                  yang sudah membuat aplikasi yang sangat bermanfaat ini!”`,
      },
      {
        image: '/images/landing/testimony-photo-2',
        name: 'Zakaria Al Anshori, S.Hut',
        title: 'Botanist Belantara Mandara,<br />Alumni Fakultas Kehutanan IPB',
        content: `“Sebagai praktisi di bidang inventarisasi sumberdaya hutan, kegiatan
                  survey terutama dalam pencatatan data biasanya dilakukan secara
                  konvensional dan akan direkap ulang setelah kegiatan selesai. Hal ini
                  membutuhkan pekerjaan ekstra setelah kegiatan survey lapang selesai
                  dan terkadang memakan waktu input data & analisis yang lama
                  apabila data survey berjumlah banyak. Kendala teknis pengambilan
                  data secara konvensional juga sering terjadi, seperti buku lapang yang
                  basah, lembaran robek, tidak terbaca, dan hilang saat kegiatan.
                  <br />
                  <br />
                  Aplikasi Inventum menawarkan kemudahan pencatatan data di lapang
                  berbasis digital atau aplikasi android dengan fitur yang handy, mudah
                  digunakan untuk mencatat data di lapangan, dan mampu memunculkan
                  output rekap data serta analisis data awal dengan format excel,
                  sehingga mengurangi waktu input data secara manual dan pekerjaan
                  menjadi lebih cepat dan efisien.”`,
      },
      {
        image: '/images/landing/testimony-photo-3',
        name: 'Iis Yuningsih, S.Hut, M.Si',
        title: 'Asisten Lapang Botani Belantara Mandara,<br />Alumni Fakultas Kehutanan IPB',
        content: `“Sebelum ada Inventum, selama ini merasa keteteran saat
                  input data, apalagi data survey. Terkadang datanya tercecer
                  kalau tulis manual (di kertas), karena sheetnya bisa sampai
                  berlembar-lembar. Selain itu, kerjaan input data manual juga
                  memerlukan waktu yang lama dan ketelitian yang extra.
                  <br />
                  <br />
                  Tetapi sejak ada Inventum, pekerjaan di lapangan menjadi
                  lebih mudah. Selain mudah dioperasikan, sekarang tidak
                  lagi menghabiskan waktu untuk input data manual, karena
                  bisa langsung terinput dan terhitung dari lapang. Walaupun
                  hp kita ngehang/mati, data yang telah kita input juga akan
                  tetap aman tersimpan di Inventum.”`,
      },
    ],
  }),
  async mounted() {
    this.setCssCustomProperties();
    this.wiggleFireflies();
    this.initListeners();
    await this.$nextTick();
    setTimeout(async () => {
      this.$rasylvaApi.init();
    }, 10);
  },
  beforeDestroy() {
    this.destroyListeners();
  },
  methods: {
    setCssCustomProperties() {
      window.document.body.style.overflow = 'hidden';
      this.setVhToWindowHeight();
      window.addEventListener('resize', debounce(this.setVhToWindowHeight, 500));
    },
    setVhToWindowHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    },
    initListeners() {
      const { testimonySlider } = this.$refs;
      testimonySlider.addEventListener('scroll', () => {
        const totalWidth = testimonySlider.scrollWidth - 40;
        this.activeTestimony = Number.parseInt((testimonySlider.scrollLeft / totalWidth) * this.testimonies.length + 1);
      });
    },
    destroyListeners() {
      const { testimonySlider } = this.$refs;
      testimonySlider.removeEventListener('scroll', this.handleScroll);
    },
    randomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    wiggleFireflies() {
      // Iterate through each firefly and set an independent timeout
      this.fireflies.forEach((firefly) => {
        const moveFirefly = () => {
          // Generate a random interval between 500ms and 3000ms for this firefly
          const randomInterval = Math.round(Math.random() * (3000 - 500)) + 500;
          firefly.transform = `translate3d(${this.randomInt(-100, 100)}%, ${this.randomInt(-100, 100)}%, 0)`;
          setTimeout(moveFirefly, randomInterval);
        };
        moveFirefly();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~/styles/variables';

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
  align-items: stretch;
  @media only screen and (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

main {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  padding-bottom: 0;
  height: 100vh;
  height: calc((var(--vh, 1vh) * 100));
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  section {
    scroll-snap-align: start;
    scroll-snap-stop: always;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    min-height: 36rem;
  }
}

.container {
  @media only screen and (min-width: 960px) {
    max-width: 960px;
    margin: auto;
    width: 100%;
  }
}

.scroll-nav {
  position: absolute;
  z-index: 9;
  right: 6vh;
  margin-right: -10px;
  top: 50%;
  margin-top: -80px;
  list-style: none;
  border: 0;
  padding: 0;
  font-size: 100%;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  li {
    border: 0;
    margin: 9px;
    font-size: 100%;
    display: list-item;
    width: 6px;
    height: 6px;
    cursor: pointer;
    div {
      background-color: #606060;
      -webkit-transition: background-color 500ms ease-in-out;
      transition: background-color 500ms ease-in-out;
      border-radius: 50%;
      width: 6px;
      height: 6px;
    }
  }
}

::v-deep .rasylva-logo-white {
  left: 50%;
  max-width: 400px;
  position: absolute;
  top: 36%;
  transform: translate(-50%, -50%);
  width: 50%;
}

::v-deep .landing-bg {
  bottom: -1px;
  height: calc(var(--vh, 1vh) * 100);
  left: 50%;
  position: relative;
  transform-origin: bottom;
  transform: translate(-50%) scale(0.8);
  vertical-align: bottom;
}

.landing-quote {
  bottom: 50px;
  font-size: 3vw;
  margin: 0;
  position: absolute;
  top: auto;
  width: 100%;
  width: 100%;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    bottom: 60px;
    font-size: 2vw;
  }
  @media only screen and (min-width: 960px) {
    bottom: 50px;
    font-size: 1.2rem;
  }
}

::v-deep .eagle {
  left: 72%;
  top: 17%;
  width: 9vh;
  @media only screen and (min-width: 960px) {
    left: 64%;
    top: 17%;
    width: 9vh;
  }
}

::v-deep .butterfly-1 {
  left: 14%;
  top: 51%;
  width: 5vh;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    left: 25%;
    top: 52%;
    width: 4vh;
  }
  @media only screen and (min-width: 960px) {
    left: 38%;
    top: 52%;
    width: 4vh;
  }
}

::v-deep .butterfly-2 {
  left: 22%;
  top: 47%;
  width: 3vh;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    left: 30%;
    top: 48%;
    width: 3vh;
  }
  @media only screen and (min-width: 960px) {
    left: 41%;
    top: 48%;
    width: 3vh;
  }
}

::v-deep .firefly {
  width: 4vh;
  transition: all 2.5s ease-in-out;
}

.sky-bg {
  background: rgba(255, 255, 255, 1);
  background: linear-gradient(
    180deg,
    rgba(30, 30, 30, 1) 0%,
    rgba(56, 147, 136, 1) 10%,
    rgba(102, 196, 186, 1) 20%,
    rgba(255, 255, 255, 1) 100%
  );
  height: calc((var(--vh, 1vh) * 100));
  min-height: 36rem;
  position: relative;
}

.sky-text {
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  margin: auto;
}

.testimony-slider {
  top: 50px;
  position: relative;
  @media only screen and (min-width: 960px) {
    top: 64px;
  }
  &__container {
    display: flex;
    height: 72vh;
    max-height: 522px;
    margin: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    width: 90%;
    @media only screen and (min-width: 960px) {
      overflow-x: auto;
      width: 80%;
      justify-content: center;
      max-height: 100%;
    }
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
    }
  }
  &__item {
    scroll-snap-align: center;
    scroll-snap-stop: always;
    position: relative;
    width: 100%;
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0 26px;
    @media only screen and (min-width: 960px) {
      width: 20vw;
      max-width: 436px;
    }
  }
  &__indicators {
    background: none;
    border-radius: 20px;
    bottom: -24px;
    display: flex;
    left: 50%;
    padding: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 9;
    &__dot {
      background: #999;
      border-radius: 4px;
      border-radius: 50%;
      border: 0;
      height: 8px;
      margin: 9px;
      width: 8px;
      &.active {
        background: $rasylva-green;
      }
      div {
        background-color: #606060;
        border-radius: 50%;
        height: 6px;
        -webkit-transition: background-color 500ms ease-in-out;
        transition: background-color 500ms ease-in-out;
        width: 6px;
      }
    }
  }
}

.testimony-bubble {
  top: 43%;
  left: 50%;
  position: absolute;
  width: 100%;
  transform: translate(-50%, -50%);
  max-width: 348px;
  @media only screen and (min-width: 960px) {
    max-width: 100%;
  }
  &__text {
    position: absolute;
    top: 0;
    margin: 20px;
    color: white;
    font-size: 1rem;
    max-height: 78%;
    overflow: auto;
    padding-right: 6px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
  }
}

::v-deep .testimony-profile {
  top: 82%;
  left: 50%;
  position: absolute;
  width: 95%;
  transform: translate(-50%);
  display: flex;
  max-width: 348px;
  @media only screen and (min-width: 960px) {
    bottom: 0;
    max-width: 100%;
  }
  &__image {
    width: 72px;
    max-width: 100px;
    margin: auto;
    display: block;
    @media only screen and (min-width: 960px) {
      max-width: 100px;
    }
  }
  &__photo,
  &__text {
    flex: 1 1 auto;
    margin: 0 4px;
  }
  &__text {
    line-height: 16px;
    b {
      line-height: 28px;
    }
    span {
      color: #666;
      font-size: 0.8rem;
    }
  }
}

.header-space {
  background: #83bb60;
  height: 56px;
  width: 100%;
  @media only screen and (min-width: 600px) {
    height: 63px;
  }
}

::v-deep .liana-left,
::v-deep .liana-right {
  max-height: 100%;
  max-width: 6vh;
  position: absolute;
  top: 55px;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    max-width: 8vh;
  }
  @media only screen and (min-width: 960px) {
    max-width: 11vh;
  }
}

::v-deep .liana-left {
  left: 0;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    left: 32px;
  }
  @media only screen and (min-width: 960px) {
    left: 64px;
  }
}

::v-deep .liana-right {
  right: 0;
  @media only screen and (min-width: 700px) and (max-width: 960px) {
    right: 32px;
  }
  @media only screen and (min-width: 960px) {
    right: 64px;
  }
}

.advantage {
  left: 50%;
  position: relative;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 90%;
  @media only screen and (min-width: 960px) {
    width: 75%;
  }
  &__item {
    margin: 0 8px;
    @media only screen and (min-width: 960px) {
      margin: 0 16px;
      padding: 0 28px;
    }
  }
}

::v-deep .landing-footer {
  bottom: 0;
  width: 100%;
  @media only screen and (min-width: 960px) {
    position: absolute;
  }
}
</style>
