<template>
  <header class="navbar-fixed" :class="{ fixed }">
    <nav>
      <div class="nav-wrapper">
        <a href="/" class="brand-logo">
          <PictureImg
            src="/images/landing/rasylva-logo-white"
            alt="Rasylva Logo White"
            :extensions="['svg', 'webp', 'png']"
            :lazy="false"
            :img-style="{ width: '80px', display: 'block' }"
          />
        </a>
        <div ref="fullMenuTrigger" class="full-menu-trigger" @click="toggleFullMenu"></div>
        <div ref="fullMenuOverlay" class="full-menu-overlay" @click="toggleFullMenu"></div>
        <div ref="fullMenu" class="full-menu" @click="toggleFullMenu">
          <ul>
            <li><a href="/#home">Home</a></li>
            <li><a href="/#products">Products</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/terms">Terms and Condition</a></li>
            <li><a href="/terms">Privacy Policy</a></li>
            <li><a href="/help">Help</a></li>
            <li><a href="/about">About</a></li>
            <li class="divider" style="margin: 8px 0"></li>
            <li>
              <a href="https://instagram.com/rasylva.id" target="_blank" rel="noreferrer">
                Instagram: <strong>@rasylva.id</strong>
              </a>
            </li>
            <li>
              <a href="https://tiktok.com/@rasylva.id" target="_blank" rel="noreferrer">
                Tiktok: <strong>@rasylva.id</strong>
              </a>
            </li>
            <li>
              <a href="https://x.com/rasylva_id" target="_blank" rel="noreferrer"> 𝕏: <strong>@rasylva_id</strong> </a>
            </li>
            <li>
              <a href="mailto:help@rasylva.id" target="_blank" rel="noreferrer">
                Email: <strong>help@rasylva.id</strong>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import PictureImg from '~/components/picture-img';

export default {
  components: {
    PictureImg,
  },
  props: {
    fixed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleFullMenu() {
      this.$refs.fullMenuTrigger.classList.toggle('opened');
      this.$refs.fullMenuOverlay.classList.toggle('opened');
      this.$refs.fullMenu.classList.toggle('opened');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~/styles/variables';

.navbar-fixed {
  &.fixed {
    position: fixed;
    nav {
      background: rgba(200, 200, 200, 0.25);
      backdrop-filter: blur(4px);
      box-shadow: 0px 4px 10px rgba(100, 100, 100, 0.2);
      border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    }
  }
  nav {
    background: $rasylva-green;
    .brand-logo {
      top: 55%;
      left: 20px;
      transform: translate(0, -50%);
    }
  }
}

.full-menu {
  background: white;
  border-radius: 0 0 0 50%;
  height: 0;
  opacity: 0;
  padding: 64px 24px;
  position: fixed;
  right: -48px;
  transition: all 0.4s ease-in-out;
  width: 0;
  @media only screen and (min-width: 993px) {
    border-radius: 0;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
  }
  ul {
    display: flex;
    flex-direction: column;
    a {
      color: $rasylva-teal;
    }
  }
  &.opened {
    background: white;
    border-radius: 0;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    opacity: 1;
    right: 0;
    width: 80vw;
    z-index: 1;
    @media only screen and (min-width: 993px) {
      width: 40vw;
    }
  }
  &-overlay {
    background: black;
    border-radius: 0;
    height: 0;
    height: 100vh;
    height: calc((var(--vh, 1vh) * 100));
    opacity: 0;
    position: fixed;
    transition: all 0.4s ease-in-out;
    visibility: hidden;
    width: 100vw;
    &.opened {
      opacity: 0.5;
      visibility: visible;
    }
  }
  &-trigger {
    background: transparent;
    height: 23px;
    position: absolute;
    right: 20px;
    top: 16px;
    width: 30px;
    cursor: pointer;
    @media only screen and (min-width: 600px) {
      top: 20px;
    }
    &:before,
    &:after {
      background: #000;
      backface-visibility: hidden;
      content: '';
      height: 2.4px;
      left: 0;
      transition: 0.75s;
      width: 30px;
    }
    &:before {
      box-shadow: #000 0 10px 0 0;
      position: absolute;
      top: 0;
    }
    &:after {
      position: absolute;
      top: calc(100% - 2px);
    }
    &.opened {
      position: fixed;
      z-index: 100;
      &:before,
      &:after {
        background: $rasylva-green;
      }
      &:before {
        box-shadow: transparent 0 0 0 0;
        top: 50%;
        transform: rotate(-225deg);
      }
      &:after {
        top: 50%;
        transform: rotate(-315deg);
      }
    }
  }
}
</style>
